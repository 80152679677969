<template>
  <div>
    <Navigation />
    <header>
      <p></p>
      <img src="@/assets/background_anniv_akeevah.png" alt="" width="100%">
    </header>

    <section>
      <h2 style="text-align: center; padding-left: 20px; padding-right: 20px;">Quelle boisson voudriez-vous prendre ?</h2>
      <ul>
        <li v-if="!['Peter Pan', 'Ondine', 'Clochette', 'Ministre De L\'Hiver'].includes(this.$store.state.tableName)"
          v-for="drink in drinks" :key="drink.id">
          <input type="checkbox" :value="drink.id" v-model="drink.checked" v-on:input="actualiserListe(drink)" />
          <h3>{{ drink.name }}</h3>
          <select v-model="drink.rating">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
          <select v-model="drink.temperatur">
            <option value="Neutre">Neutre</option>
            <option value="Frais">Frais</option>
          </select>
        </li>
        <li v-if="['Peter Pan', 'Ondine', 'Clochette', 'Ministre De L\'Hiver'].includes(this.$store.state.tableName)"
          v-for="drink in kidsDrinks" :key="drink.id">
          <input type="checkbox" :value="drink.id" v-model="drink.checked" v-on:input="actualiserListe(drink)" />
          <h3>{{ drink.name }}</h3>
          <select v-model="drink.rating">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
          <select v-model="drink.temperatur">
            <option value="Neutre">Neutre</option>
            <option value="Frais">Frais</option>
          </select>
        </li>
      </ul>
    </section>

    <section class="button-style">
      <h4 v-if="this.boissonsSelectionnees.length > 0">
        Boissons sélectionnées :
        <ul>
          <li v-for="drink in this.boissonsSelectionnees">
            x{{ drink.rating }} {{ drink.name }} ({{ drink.temperatur }})
          </li>
        </ul>
      </h4>
      <div style="display: flex; justify-content: flex-start; align-items: flex-end;">
        <button @click="this.showDialog = true">Envoyer la liste </button>
        <p> </p>
        <button style="padding: 10px; background-color: #a50c0c;" v-if="this.boissonsSelectionnees.length > 0"
          v-on:click="effacerListe()">Effacer vos choix</button>
      </div>
    </section>
    <Dialog :show="showDialog" :cancel="doSomething" :confirm="envoyerListe" title="Choix"
      description="Veux-tu vraiment confirmer cette liste de boissons ?" />
    <h2 v-if="this.$store.state.recap !== '' && this.$store.state.recap !== null" style="text-align: center;">Resumé de
      votre commande:
    </h2>
    <h4 style="text-align: center;" v-if="this.$store.state.recap !== ''">
      {{ this.$store.state.recap }}
    </h4>

    <footer>
      <img v-if="this.$store.state.tableName === 'Clochette'" src="@/assets/Clochette.png" alt="" width="100%">
      <img v-if="this.$store.state.tableName === 'Peter Pan'" src="@/assets/Peter Pan.png" alt="" width="100%">
      <img v-if="this.$store.state.tableName === 'Roselia'" src="@/assets/Roselia.png" alt="" width="100%">
      <img v-if="this.$store.state.tableName === 'Emily'" src="@/assets/Emily.png" alt="" width="100%">
      <img v-if="this.$store.state.tableName === 'Iridessa'" src="@/assets/Iridessa.png" alt="" width="100%">
      <img v-if="this.$store.state.tableName === 'Ondine'" src="@/assets/Ondine.png" alt="" width="100%">
      <img v-if="this.$store.state.tableName === 'Vidia'" src="@/assets/Vidia.png" alt="" width="100%">
      <img v-if="this.$store.state.tableName === 'Terence'" src="@/assets/Terence.png" alt="" width="100%">
      <img v-if="this.$store.state.tableName === 'Reine Clarion'" src="@/assets/Reine Clarion.png" alt="" width="100%">
      <img v-if="this.$store.state.tableName === 'Ministre De L\'Été'" src="@/assets/Ministre Ete.png" alt=""
        width="100%">
      <img v-if="this.$store.state.tableName === 'Ministre De L\'Hiver'" src="@/assets/Ministre Hiver.png" alt=""
        width="100%">
      <img v-if="this.$store.state.tableName === 'Ministre De L\'Automne'" src="@/assets/Ministre Automne.png" alt=""
        width="100%">
      <img v-if="this.$store.state.tableName === 'Ministre Du Printemps'" src="@/assets/Ministre Printemps.png" alt=""
        width="100%">
    </footer>
  </div>
</template>

<style scoped>
h2 {
  animation: color-change 1.5s infinite;
  font-size: 2em;
  font-weight: bold;
}

p {
  padding: 100px;
}

img {
  float: left;
}

@keyframes color-change {
  0% {
    color: #f2acd3;
  }

  25% {
    color: #ddfff0;
  }

  50% {
    color: #e4f6fd;
  }

  75% {
    color: #ddfff0;
  }

  100% {
    color: #f2acd3;
  }
}

section {
  background-color: #e4f6fd;
  padding: 10px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

input {
  margin-right: 10px;
}

h2 {
  font-size: 1.5em;
  color: #224a48;
  padding: 20px;
}

h3 {
  font-size: 1.5em;
  color: #a36868;
  padding: 5px;
}

footer {
  padding: 0px;
}

p {
  padding: 30px;
}

button {
  background-color: #000000;
  color: #ffffff;
  font-size: 1.2em;
  padding: 10px;
  cursor: pointer;
  border-radius: 5%;
}

.button-style {
  background-color: #ddfff0;
  padding: 20px;
}
</style>

<script>
import Navigation from './Navigation.vue';
import Dialog from './Dialog.vue';

export default {
  name: "Commandes",
  components: {
    Navigation,
    Dialog,
  },
  mounted() {
    if (localStorage.getItem("recap") === null) {
      localStorage.clear();
    } else {
      this.$store.state.recap = localStorage.getItem("recap");
    }
    this.$store.state.tableName = sessionStorage.getItem("checkTableName");
  },
  data() {
    return {
      showDialog: false,
      drinks: [
        {
          id: 1,
          name: "Eau plate (0,5l)",
          type: "water",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 2,
          name: "Eau plate (0,7l)",
          type: "water",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 3,
          name: "Mezzo-Mix (0,33l)",
          type: "juice",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 4,
          name: "Fanta (0,33l)",
          type: "juice",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 5,
          name: "Coca-Cola (0,33l)",
          type: "juice",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 6,
          name: "Tonic (0,5l)",
          type: "juice",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 7,
          name: "Tonic (0,7l)",
          type: "juice",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 8,
          name: "Jus de pomme gazeux (0,5l)",
          type: "juice",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 9,
          name: "Jus d'orange (1l)",
          type: "juice",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 10,
          name: "Coca-Cola (1l)",
          type: "juice",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 11,
          name: "Bière Pils (0,33l)",
          type: "alcohol",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 12,
          name: "Bière Radler (0,33l)",
          type: "alcohol",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 13,
          name: "Hefe-Weiß (Erdinger/Brauwerk) (0,5l)",
          type: "alcohol",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 14,
          name: "Sekt (vin mousseux) blanc (0,75l)",
          type: "alcohol",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 15,
          name: "Sekt (vin mousseux) rose (0,75l)",
          type: "alcohol",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 16,
          name: "Pinot noir blanc automne (1l)",
          type: "alcohol",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 17,
          name: "Vin blanc Müller (1l)",
          type: "alcohol",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 18,
          name: "Vin blanc Rießling (1l)",
          type: "alcohol",
          rating: 1,
          temperatur: "Neutre",
        }
      ],
      kidsDrinks: [
      {
          id: 1,
          name: "Eau plate (0,5l)",
          type: "water",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 2,
          name: "Eau plate (0,7l)",
          type: "water",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 3,
          name: "Mezzo-Mix (0,33l)",
          type: "juice",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 4,
          name: "Fanta (0,33l)",
          type: "juice",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 5,
          name: "Coca-Cola (0,33l)",
          type: "juice",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 6,
          name: "Tonic (0,5l)",
          type: "juice",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 7,
          name: "Tonic (0,7l)",
          type: "juice",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 8,
          name: "Jus de pomme gazeux (0,5l)",
          type: "juice",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 9,
          name: "Jus d'orange (1l)",
          type: "juice",
          rating: 1,
          temperatur: "Neutre",
        },
        {
          id: 10,
          name: "Coca-Cola (1l)",
          type: "juice",
          rating: 1,
          temperatur: "Neutre",
        }
      ],
      boissonsSelectionnees: [],
    };
  },

  methods: {
    actualiserListe(drink) {
      // Si la boisson est sélectionnée, ajoutez-la à l'array
      if (!this.boissonsSelectionnees.some(b => b.id === drink.id)) {
        this.boissonsSelectionnees.push(drink);
      } else {
        this.boissonsSelectionnees.splice(this.boissonsSelectionnees.indexOf(drink), 1);
      }
    },
    effacerListe() {
      this.boissonsSelectionnees.length = 0;

      // Parcourrez toutes les boissons et déselectionnez-les
      for (const drink of this.drinks) {
        drink.checked = false;
        drink.rating = 1;
        drink.temperatur = "Neutre";
      }

      for (const drink of this.kidsDrinks) {
        drink.checked = false;
        drink.rating = 1;
        drink.temperatur = "Neutre";
      }
    },
    doSomething() {
      // Fermer le dialog
      this.showDialog = false;
    },
    envoyerListe() {
      this.showDialog = false;
      // Vérifiez si la liste des boissons sélectionnées est vide
      if (this.boissonsSelectionnees.length === 0) {
        // Afficher une erreur
        alert("Vous devez sélectionner au moins une boisson.");
        return;
      }
      this.$store.state.boissonsSelectionnees.length = 0;
      this.$store.state.boissonsSelectionnees = this.boissonsSelectionnees.slice(0);
      localStorage.setItem("selectedDrinks", this.$store.state.boissonsSelectionnees);
      // Créez un message WhatsApp
      let message = "SVP apportez les boissons mentionnées à la table *";
      let table = window.location.search.split("table=")[1];
      let decodeTable = decodeURIComponent(table);
      if (this.$store.state.tableName !== "Peter Pan") {
        message += "Fée ";
      } else {
        message += "";
      }
      message = message.concat(decodeTable);
      message += "*:";
      this.$store.state.boissonsSelectionnees.forEach(drink => {
        message += `\nx${drink.rating} ${drink.name} (${drink.temperatur})`;
        this.$store.state.recap += `x${drink.rating} ${drink.name} (${drink.temperatur})\n`;
        localStorage.setItem("recap", this.$store.state.recap);
      });

      // Envoyez le message
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("token", "giqr47e4qiv39hb9");
      urlencoded.append("to", "120363197938869625@g.us");
      urlencoded.append("body", message);


      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      fetch("https://api.ultramsg.com/instance73045/messages/chat", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));

      this.effacerListe();

      alert("Votre liste de boissons a été bien envoyée. Veuillez patienter quelque minutes.");
    },
  },
};
</script>
