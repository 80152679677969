<template>
    <div>
        <header>
            <img src="@/assets/background_anniv_akeevah.png" alt="" width="100%">
            <p></p>
            <h2 style="text-align: center; margin: 20px 0; padding-left: 20px; padding-right: 20px;">Il y a quelqu'un qui
                joue avec les tables là!</h2>
        </header>
        <main>
            <div style="text-align: center; padding-left: 20px; padding-right: 20px;" class="alert alert-info">
                <p></p>
                <strong v-if="this.$store.state.tableName !== 'Peter Pan'" style="margin-bottom: 10px">Tu étais déjà sur la
                    table <strong style="text-decoration: underline;">Fée {{ this.$store.state.tableName }}</strong>
                    non?!</strong> <br />
                <strong v-if="this.$store.state.tableName === 'Peter Pan'" style="margin-bottom: 10px">Tu étais déjà sur la
                    table <strong style="text-decoration: underline;">{{ this.$store.state.tableName }}</strong>
                    non?!</strong> <br />
                Laisse-moi te rediriger.
            </div>
            <div style="text-align: center; margin: 20px 0; padding: 20px" class="row">
                <p></p>
                <button v-on:click="goBack()">Revenir</button>
            </div>
        </main>
        <footer style="margin-bottom: 0px margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Clochette'" src="@/assets/Clochette.png" alt="" width="100%"
                style="margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Peter Pan'" src="@/assets/Peter Pan.png" alt="" width="100%"
                style="margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Roselia'" src="@/assets/Roselia.png" alt="" width="100%"
                style="margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Emily'" src="@/assets/Emily.png" alt="" width="100%"
                style="margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Iridessa'" src="@/assets/Iridessa.png" alt="" width="100%"
                style="margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Ondine'" src="@/assets/Ondine.png" alt="" width="100%"
                style="margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Vidia'" src="@/assets/Vidia.png" alt="" width="100%"
                style="margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Terence'" src="@/assets/Terence.png" alt="" width="100%"
                style="margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Reine Clarion'" src="@/assets/Reine Clarion.png" alt="" width="100%"
                style="margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Ministre De L\'Été'" src="@/assets/Ministre Ete.png" alt=""
                width="100%" style="margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Ministre De L\'Hiver'" src="@/assets/Ministre Hiver.png" alt=""
                width="100%" style="margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Ministre De L\'Automne'" src="@/assets/Ministre Automne.png" alt=""
                width="100%" style="margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Ministre Du Printemps'" src="@/assets/Ministre Printemps.png" alt=""
                width="100%" style="margin-bottom: 0;">
        </footer>
    </div>
</template>
  
<script>
// @ is an alias to /src

export default {
    name: "ReturnTable",
    components: {},
    mounted() {
        this.$store.state.tableName = sessionStorage.getItem("checkTableName");
    },
    methods: {
        goBack() {
            this.$router.push("/?table=" + encodeURIComponent(this.$store.state.tableName));
        },
    }
};
</script>
  
<style lang="scss" scoped>
p {
    padding: 37px;
}

footer {
    padding: 0px;
}

button {
    background-color: #000000;
    color: #ffffff;
    font-size: 1.2em;
    padding: 10px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 5%;
}</style>