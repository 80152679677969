import { createStore } from 'vuex'

export default createStore({
  state: {
    tableName: "",
    checkTableName: "",
    recap: "",
    message: "",
    tableArray: ["Peter Pan", "Clochette", "Iridessa", "Roselia", "Terence", "Vidia", "Ondine", "Emily", "Reine Clarion", "Ministre De L'Été", "Ministre De L'Hiver", "Ministre De L'Automne", "Ministre Du Printemps"],
    boissonsSelectionnees: [],
  },
  getters: {
    getTableName: (state) => state.tableName,
  },
  mutations: {
    setTableName(state, tableName) {
      state.tableName = tableName;
    },
    setCheckTableName(state, checkTableName) {
      state.checkTableName = checkTableName;
    },
    setRecap(state, recap) {
      state.recap = recap;
    },
    setMessage(state, message) {
      state.message = message;
    },
    setTableArray(state, newTableArray) {
      state.tableArray = newTableArray;
    },
    setBoissonsSelectionnees(state, boissonsSelectionnees) {
      state.boissonsSelectionnees = boissonsSelectionnees;
    },
  },
  actions: {
  },
  modules: {
  }
})