<template>
    <header :class="{ 'scrolled-nav': scrollNav }">
        <nav>
            <div class="branding">
                <img src="@/assets/favicon_akeevah.png" alt="">
                <h4 v-if="this.$store.state.tableName !== 'Peter Pan'" style="padding-left: 20px; padding-right: 20px;">
                    Table Fée {{ this.$store.state.tableName }}</h4>
                <h4 v-if="this.$store.state.tableName === 'Peter Pan'" style="padding-left: 20px; padding-right: 20px;">
                    Table {{ this.$store.state.tableName }}</h4>
                <div style="margin-top: 10px;"> </div>
            </div>
            <ul v-show="!mobile" class="navigation">
                <li><router-link class="link" :to="{ path: '/', query: { table: this.$store.state.tableName } }"
                        active-class="active">Commandes</router-link></li>
                <li><router-link class="link" :to="{ name: 'Photos' }">Photos</router-link></li>
            </ul>
            <div class="icon">
                <i @click="toggleMobileNav" v-show="mobile" class="far fa-bars" :class="{ 'icon-active': mobileNav }"></i>
            </div>
            <transition name="mobile-nav">
                <ul v-show="mobileNav" class="dropdown-nav">
                    <li><router-link class="link" :to="{ path: '/', query: { table: this.$store.state.tableName } }"
                            active-class="active" v-on:click="toggleMobileNav()">Commandes</router-link></li>
                    <li><router-link class="link" v-on:click="toggleMobileNav()"
                            :to="{ name: 'Photos' }">Photos</router-link></li>
                </ul>
            </transition>
        </nav>
    </header>
</template>
  
<script>
export default {
    name: "navigation",
    data() {
        return {
            scrollPosition: null,
            mobile: null,
            mobileNav: null,
            windowWidth: null,
        };
    },

    created() {
        window.addEventListener("resize", this.checkScreen);
        this.checkScreen();
    },
    mounted() {
        window.addEventListener("scroll", this.updateScroll);
        this.setTableName();
    },
    methods: {
        setTableName() {
            const table = window.location.search.split("table=")[1];
            const decodeTable = decodeURIComponent(table);

            switch (true) {
                case window.location.search.includes("table=") && this.$store.state.tableArray.includes(decodeTable) && window.location.pathname === "/":
                    this.$store.state.tableName = decodeTable;
                    sessionStorage.setItem("checkTableName", decodeTable);
                    break;
                case window.location.search.includes("table=") && sessionStorage.getItem("checkTableName") !== null && !this.$store.state.tableArray.includes(decodeTable) && window.location.pathname === "/":
                    this.$router.push("/revenir-sur-table");
                    break;
                case window.location.search.includes("table=") && sessionStorage.getItem("checkTableName") === null && !this.$store.state.tableArray.includes(decodeTable) && window.location.pathname === "/":
                    this.$router.push("/mauvaise-table");
                    break;
                case this.$store.state.tableArray.includes(this.$store.state.tableName) && window.location.pathname === "/photos":
                    break;
                case window.location.search.includes("table=") && sessionStorage.getItem("checkTableName") !== null && !this.$store.state.tableArray.includes(decodeTable) && window.location.pathname === "/photos":
                    this.$router.push("/revenir-sur-table");
                    break;
                case sessionStorage.getItem("checkTableName") !== null && !this.$store.state.tableArray.includes(decodeTable) && window.location.pathname === "/photos":
                    this.$store.state.tableName = sessionStorage.getItem("checkTableName");
                    this.$router.push("/photos");
                    break;
                case sessionStorage.getItem("checkTableName") === null:
                    this.$router.push("/scan-qr-code");
                    break;
                default:
                    this.$router.push("/perdu");
            }

            console.log(sessionStorage.getItem("checkTableName"));
        },

        goBackAndClose() {
            this.goBack();
            this.toggleMobileNav();
        },

        goBack() {
            window.history.back();
        },

        toggleMobileNav() {
            this.mobileNav = !this.mobileNav;
        },

        updateScroll() {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 50) {
                this.scrolledNav = true;
                return;
            }
            this.scrollNav = false;
        },

        checkScreen() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth <= 750) {
                this.mobile = true;
                return;
            }
            this.mobile = false;
            this.mobileNav = false;
            return;
        }
    },
};
</script>
  
<style lang="scss" scoped>
header {
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 99;
    width: 100%;
    position: fixed;
    transition: 0.5s ease all;
    color: #fff;


    nav {
        position: relative;
        display: flex;
        flex-direction: row;
        padding: 12px 0;
        transition: 0.5s ease all;
        width: 90%;
        margin: 0 auto;

        @media (min-width: 1140px) {
            max-width: 1140px;
        }

        ul,
        .link {
            font-weight: 500;
            color: #fff;
            list-style: none;
            text-decoration: none;
        }

        li {
            text-transform: uppercase;
            padding: 16px;
            margin-left: 16px;
        }

        .link {
            font-size: 14px;
            transition: 0.5s ease all;
            padding-bottom: 4px;
            border-bottom: 1px solid transparent;

            &:hover {
                color: #00afea;
                border-color: #00afea;
            }
        }

        .branding {
            display: flex;
            align-items: center;

            img {
                width: 50px;
                transition: 0.5s ease all;
            }
        }

        .navigation {
            display: flex;
            align-items: center;
            flex: 1;
            justify-content: flex-end;
        }

        .icon {
            display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            right: 24px;
            height: 100%;

            i {
                cursor: pointer;
                font-size: 24px;
                transition: 0.8s ease all;
            }
        }

        .icon-active {
            transform: rotate(180deg);
        }

        .dropdown-nav {
            display: flex;
            flex-direction: column;
            position: fixed;
            width: 100%;
            max-width: 250px;
            height: 100%;
            background-color: #fff;
            top: 0;
            left: 0;

            li {
                margin-left: 0;

                .link {
                    color: #000;
                }
            }
        }

        .mobile-nav-enter-active,
        .mobile-nav-leave-active {
            transition: 1s ease all;
        }

        .mobile-nav-enter-from,
        .mobile-nav-leave-to {
            transform: translateX(-250px);
        }

        .mobile-nav-enter-to {
            transform: translateX(0);
        }
    }
}

.scrolled-nav {
    background-color: #000;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

    nav {
        padding: 8px 0;

        .branding {
            img {
                width: 40px;
                box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
            }
        }
    }
}
</style>