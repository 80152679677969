<template>
    <div v-show="show" class="overlay">
  
  
      <div class="dialog">
  
        <div class="dialog__content">
          <h2 class="dialog__title" style="text-align: center;" v-text="title"></h2>
          <p class="dialog__description" v-text="description"></p>
        </div>
  
        <hr />
  
        <div class="dialog__footer">
          <button @click="cancel" class="dialog__cancel">Non, attends je recommence</button>
          <button @click="confirm" class="dialog__confirm">Oui, vas-y apporte tout ça</button>
        </div>
  
      </div>
  
    </div>
  </template>
  
  <script>
  export default {
      props: ['show', 'title', 'description', 'cancel', 'confirm']
  }
  </script>