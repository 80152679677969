import { createRouter, createWebHistory } from "vue-router";
import DrinkList from "../components/DrinksList.vue";
import Photos from "../components/Photos.vue";
import Erreur from "../components/Error.vue";
import Lost from "../components/Lost.vue";
import ReturnTable from "../components/ReturnTable.vue";
import ScanQRCode from "../components/ScanQRCode.vue";

const routes = [
  {
    path: "/",
    name: "Commandes",
    component: DrinkList,
  },
  {
    path: "/photos",
    name: "Photos",
    component: Photos,
    beforeLeave: (to, from, next) => {
      // Vérifiez la condition
      if (to.path == "/index.html") {
        // La route est redirigée
        next({ name: "Photos" });
      } else {
        // La route est chargée
        next();
      }
    },
  },
  {
    path: "/perdu",
    name: "Perdu",
    component: Erreur,
  },
  {
    path: "/mauvaise-table",
    name: "Lost",
    component: Lost,
  },
  {
    path: "/revenir-sur-table",
    name: "ReturnTable",
    component: ReturnTable,
  },
  {
    path: "/scan-qr-code",
    name: "ScanQRCode",
    component: ScanQRCode,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Erreur",
    component: Erreur,
    beforeEnter: (to, from, next) => {
      // Vérifiez la condition
      if (sessionStorage.getItem("checkTableName") === null) {
        // La route est redirigée
        next({ name: "ScanQRCode" });
      } else if (sessionStorage.getItem("checkTableName") === null && from.path === "/photos" ) {
        // La route est chargée
        next({ name: "Photos" });
      } else if (sessionStorage.getItem("checkTableName") === null && to.path === "/photos" ) {
        // La route est chargée
        next({ name: "Photos" });
      } else {
        // La route est chargée
        next();
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;