<template>
    <div>
        <header>
            <img src="@/assets/background_anniv_akeevah.png" alt="" width="100%">
            <p></p>
            <h2 style="text-align: center; margin: 20px 0; padding-left: 20px; padding-right: 20px;">La consigne était simple non je crois</h2>
        </header>
        <main>
            <div style="text-align: center; padding-left: 20px; padding-right: 20px;" class="alert alert-info">
                <p></p>
                <strong style="margin-bottom: 10px">Si tu veux entrer dans l'application, ce ne sera pas de cette façon que tu y arriveras ...</strong>
            </div>
            <div style="text-align: center; padding-left: 20px; padding-right: 20px;" class="alert alert-info">
                <p></p>
                <strong style="margin-bottom: 10px">... alors scanne le <strong style="text-decoration: underline;">QR Code</strong> qui se trouve sur ta table.</strong>
            </div>
        </main>
        <footer style="margin-bottom: 0px margin-bottom: 0;">
            <img src="@/assets/footer_anniv_akeevah.png" alt="" width="100%" style="margin-bottom: 0;">
        </footer>
    </div>
</template>
  
<script>
// @ is an alias to /src

export default {
    name: "Lost",
    components: {},
    mounted() {
        this.$store.state.tableName = localStorage.getItem("checkTableName");
    },
    methods: {
        goBack() {
            window.history.back();
        },
    }
};
</script>
  
<style lang="scss" scoped>
p {
    padding: 37px;
}

footer {
    padding: 0px;
}

button {
    background-color: #000000;
    color: #ffffff;
    font-size: 1.2em;
    padding: 10px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 5%;
}
</style>