<template>
    <div>
        <Navigation />
        <header>
            <p></p>
            <img src="@/assets/background_anniv_akeevah.png" alt="" width="100%">
            <h1 style="text-align: center; margin: 20px 0;">Photos</h1>
        </header>
        <main>
            <div style="text-align: center; padding-left: 20px; padding-right: 20px;" class="alert alert-info">
                <strong style="margin-bottom: 10px">Les photos prises le jour de la fête seront publiées ici même.</strong>
                <br />
                En attendant, vous pouvez regarder ces quelque clichés de la star du jour.
            </div>
            <div style="text-align: center; margin: 20px 0; padding: 20px" class="row">
                <div class="col-md-4 d-flex justify-content-between">
                    <img style="padding: 10px" class="cliche" src="@/assets/photo_akeevah_0.jpeg" alt="Cliché 1"
                        width="33.33%">
                </div>
                <div class="col-md-4 d-flex justify-content-between">
                    <img style="padding: 10px" class="cliche" src="@/assets/photo_akeevah_1.jpeg" alt="Cliché 2"
                        width="33.33%">
                    <img style="padding: 10px" class="cliche" src="@/assets/photo_akeevah_2.jpeg" alt="Cliché 3"
                        width="33.33%">
                </div>
                <div class="col-md-4 d-flex justify-content-between">
                    <img style="padding: 10px" class="cliche" src="@/assets/photo_akeevah_3.jpeg" alt="Cliché 4"
                        width="33.33%">
                    <img style="padding: 10px" class="cliche" src="@/assets/photo_akeevah_4.jpeg" alt="Cliché 5"
                        width="33.33%">
                </div>
                <div class="col-md-4 d-flex justify-content-between">
                    <img style="padding: 10px" class="cliche" src="@/assets/photo_akeevah_5.jpeg" alt="Cliché 6"
                        width="33.33%">
                </div>
            </div>
            <div style="text-align: center; margin: 20px 0; padding: 20px" class="row">
                <button v-on:click="goBack()">Retour</button>
            </div>
        </main>
        <footer style="margin-bottom: 0px">
            <img v-if="this.$store.state.tableName === 'Clochette'" src="@/assets/Clochette.png" alt="" width="100%" style="margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Peter Pan'" src="@/assets/Peter Pan.png" alt="" width="100%" style="margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Roselia'" src="@/assets/Roselia.png" alt="" width="100%" style="margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Emily'" src="@/assets/Emily.png" alt="" width="100%" style="margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Iridessa'" src="@/assets/Iridessa.png" alt="" width="100%" style="margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Ondine'" src="@/assets/Ondine.png" alt="" width="100%" style="margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Vidia'" src="@/assets/Vidia.png" alt="" width="100%" style="margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Terence'" src="@/assets/Terence.png" alt="" width="100%" style="margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Reine Clarion'" src="@/assets/Reine Clarion.png" alt=""
                width="100%" style="margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Ministre De L\'Été'" src="@/assets/Ministre Ete.png" alt=""
                width="100%" style="margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Ministre De L\'Hiver'" src="@/assets/Ministre Hiver.png" alt=""
                width="100%" style="margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Ministre De L\'Automne'" src="@/assets/Ministre Automne.png" alt=""
                width="100%" style="margin-bottom: 0;">
            <img v-if="this.$store.state.tableName === 'Ministre Du Printemps'" src="@/assets/Ministre Printemps.png" alt=""
                width="100%" style="margin-bottom: 0;">
        </footer>
    </div>
</template>
  
<script>
// @ is an alias to /src
import Navigation from './Navigation.vue';

export default {
    name: "Photos",
    components: { Navigation },
    methods: {
        goBack() {
            window.history.back();
        },
    }
};
</script>
  
<style lang="scss" scoped>
p {
    padding: 37px;
}

footer {
    padding: 0px;
}

img {
    float: left;
}

button {
    background-color: #000000;
    color: #ffffff;
    font-size: 1.2em;
    padding: 10px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 5%;
}</style>